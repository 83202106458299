<template>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-6 col-sm-4 col-md-3 col-lg-2" v-for="(champion, ind) in champions" :key="ind">
          <div class="champion">
              <img :src="champion.image" alt="Imagen de la copa" class="img-fluid cup">
              <div class="col-right">
                  <img src="../../../assets/images/ganador.png" alt="Ganador" class="img-fluid win">
                  <router-link :to="{ name: 'TeamHome', params: { lang: lang, console: console, teamSlug: champion.team_slug } }">
                    <img :src="champion.team_image" alt="Equipo ganador" class="img-fluid team">
                  </router-link>
              </div>
              <div class="text-center w-100 pt-3">
                  <router-link :to="{ name: 'TournamentHome', params: { lang: lang, console: console, tournamentSlug: champion.tournament_slug } }">{{ champion.tournament_name }}</router-link><br>
                  <router-link :to="{ name: 'DivisionHome', params: { lang: lang, console: console, divisionSlug: champion.division_slug } }">{{ champion.division_name }}</router-link><br>
                  <span>{{ champion.season }}</span><br>
                  <span>{{ $t('end') }} {{ champion.date_delivery }}</span>
                  <br>
                  <router-link :to="{ name: 'PlayerHome', params: { lang: lang, console: console, playerSlug: champion.user_slug } }">{{ champion.user_nick }}</router-link>
              </div>
          </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
        champions: []
    }
  },
  computed: {
    ...mapGetters([
      'lang',
      'console'
    ])
  },
  mounted() {
    this.$store.dispatch('SET_MENU_PRIMARY_DIVISION', '')
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      const lang = this.$route.params.lang
      const console = this.$route.params.console
      const slug = this.$route.params.divisionSlug
      this.path = `${lang}/console/${console}/division/${slug}/champions`
      this.$axios.get(this.path).then((response) => {
        this.champions = response.data.data
      })
    }
  }
}
</script>
